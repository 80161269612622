import { LiveAnnouncer } from '@angular/cdk/a11y';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ContentChildren,
  Input,
  QueryList,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
  inject,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, UntypedFormControl, Validators } from '@angular/forms';
import { MatChipEditedEvent, MatChipGrid, MatChipInputEvent } from '@angular/material/chips';
import * as Directives from './chips-input.directive';

@Component({
  selector: 'chips-input',
  templateUrl: './chips-input.component.html',
  styleUrls: ['./chips-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipsInputComponent),
      multi: true,
    },
  ],
  host: {
    style: 'display: flex; flex-direction: row;',
    class: 'custom-chips-input',
    '[class.invalid]': 'required && chips.length === 0',
    '[class.disabled]': 'control.disabled',
    '[class.touched]': 'control.touched',
  },
})
export class ChipsInputComponent implements ControlValueAccessor {
  @ContentChildren(Directives.ChipsTopHintDirective, { descendants: true })
  public chipsTopHintChildren!: QueryList<Directives.ChipsTopHintDirective>;
  @ViewChild('chipGrid') chipGrid!: MatChipGrid;
  @Input() placeholder!: string;
  @Input() required = false;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  public chipsControl: UntypedFormControl;
  public chips: string[] = [];
  private announcer = inject(LiveAnnouncer);

  constructor() {
    this.chipsControl = new UntypedFormControl('', this.required ? Validators.required : null);
  }

  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};

  public get control(): NgControl | {} {
    return this.chipsControl || {};
  }

  public writeValue(value: any): void {
    this.chips = value || [];
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public add(event: MatChipInputEvent): void {
    const input = event.chipInput?.inputElement;
    const value = event.value;

    if ((value || '').trim()) {
      this.chips.push(value.trim());
      this.onChange(this.chips);
    }

    if (input) {
      input.value = '';
    }
  }

  public remove(chip: string): void {
    const index = this.chips.indexOf(chip);
    if (index !== -1) {
      this.chips.splice(index, 1);
      this.onChange(this.chips);
    }
  }

  public edit(event: MatChipEditedEvent, index: number) {
    const value = event.value.trim();
    // Remove element if it no longer has a name
    if (!value) {
      this.remove(value);
      return;
    }

    // Edit existing element
    this.chips[index] = value;
    this.onChange(this.chips);
  }
}
