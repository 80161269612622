import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { ClickOutsideModule } from '@common/directives/click-outside/click-outside.module';
import { LineLoaderComponent } from '@common/modules/UI/line-loader/line-loader.component';
import { UserImgModule } from '@common/modules/UI/user-img/user-img.module';
import { NotificationsService } from '@common/modules/header/services/notifications.service';
import { DeleteIconModule } from '../UI/delete-icon/delete-icon.module';
import { NotificationsIconModule } from '../UI/notifications-icon/notifications-icon.module';
import { NotificationsListComponent } from './components/notifications-list/notifications-list.component';
import { HeaderComponent } from './header.component';

@NgModule({
  declarations: [HeaderComponent, NotificationsListComponent],
  imports: [
    CommonModule,
    RouterLink,
    MatDialogModule,
    ClickOutsideModule,
    UserImgModule,
    MatIconModule,
    DeleteIconModule,
    NotificationsIconModule,
    LineLoaderComponent,
  ],
  exports: [HeaderComponent],
  providers: [NotificationsService],
})
export class HeaderModule {}
