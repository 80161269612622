import { IBuildingMainData } from '@common/interfaces/building-response.interface';
import { IBuildingCategory } from '@common/interfaces/departments.interface';
import { IBuildingInspection } from '@common/interfaces/inspections.interface';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as BuildingActions from './building.actions';

export interface BuildingState {
  buildingData: IBuildingMainData | null;
  energy: IBuildingCategory[];
  sustainability: IBuildingCategory[];
  envelop: IBuildingCategory[];
  inspections: IBuildingInspection[] | [];
  isLoading: boolean;
  error: string | null;
}

export const initialBuildingsState: BuildingState = {
  buildingData: null,
  energy: [],
  sustainability: [],
  envelop: [],
  inspections: [],
  isLoading: false,
  error: null,
};
export const _buildingReducer = createReducer(
  initialBuildingsState,
  on(BuildingActions.ClearBuildingDataStoreAction, (): BuildingState => ({ ...initialBuildingsState })),
  on(BuildingActions.GetBuildingDataAction, (state): BuildingState => ({ ...state, isLoading: true })),
  on(
    BuildingActions.SetBuildingDataAction,
    (state: BuildingState, { buildingData }): BuildingState => ({
      ...state,
      buildingData,
      isLoading: false,
      error: null,
    })
  ),

  on(
    BuildingActions.GetBuildingDepartmentDataAction,
    (state: BuildingState): BuildingState => ({
      ...state,
      isLoading: true,
    })
  ),

  // Energy
  on(
    BuildingActions.SetBuildingEnergyDepartmentDataAction,
    (state: BuildingState, { energy }): BuildingState => ({
      ...state,
      energy,
      isLoading: false,
      error: null,
    })
  ),
  // Sustainability
  on(
    BuildingActions.SetBuildingSustainabilityDepartmentDataAction,
    (state: BuildingState, { sustainability }): BuildingState => ({
      ...state,
      sustainability,
      isLoading: false,
      error: null,
    })
  ),
  // Envelop
  on(
    BuildingActions.SetBuildingEnvelopDepartmentDataAction,
    (state: BuildingState, { envelop }): BuildingState => ({
      ...state,
      envelop,
      isLoading: false,
      error: null,
    })
  ),

  //   Inspections
  on(
    BuildingActions.SetBuildingDataInspections,
    (state: BuildingState, { inspections }: { inspections: IBuildingInspection[] }): BuildingState => ({
      ...state,
      inspections,
      isLoading: false,
      error: null,
    })
  )
);

export const selectBuildingsState = createFeatureSelector<BuildingState>('buildingData');

export const selectBuildingStateLoading = createSelector(selectBuildingsState, (state: BuildingState) => {
  return state.isLoading;
});

export const selectBuildingData = createSelector(selectBuildingsState, (state: BuildingState) => {
  return state.buildingData;
});

export const selectBuildingDepartments = createSelector(selectBuildingsState, (state: BuildingState) => {
  return { buildingDepartments: state.buildingData?.departments };
});

export const selectBuildingDataAndId = createSelector(selectBuildingsState, (state: BuildingState) => {
  return { buildingData: state.buildingData, buildingId: state.buildingData?.id };
});
export const selectBuildingId = createSelector(
  selectBuildingsState,
  (
    state: BuildingState
  ): {
    buildingId: number | null;
  } => {
    return { buildingId: (state.buildingData && state.buildingData.id) ?? null };
  }
);

export const selectBuildingEnergyDepartmentData = createSelector(selectBuildingsState, (state: BuildingState) => {
  return state.energy;
});

export const selectBuildingSustainabilityDepartmentData = createSelector(
  selectBuildingsState,
  (state: BuildingState) => {
    return state.sustainability;
  }
);

export const selectBuildingEnvelopDepartmentData = createSelector(selectBuildingsState, (state: BuildingState) => {
  return state.envelop;
});

export const selectBuildingLoadingStatus = createSelector(selectBuildingsState, (state: BuildingState) => {
  return state.isLoading;
});

export function buildingReducer(state: BuildingState | undefined, action: Action) {
  return _buildingReducer(state, action);
}
