import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRes } from '@common/interfaces/base-table.interface';
import { IParamsRequestKeys } from '@common/interfaces/base.interfaces';
import { ICompany } from '@common/interfaces/company.interface';
import { IContact } from '@common/interfaces/contact.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CompaniesApiService {
  constructor(private http: HttpClient) {}

  public getCompaniesList(params?: IParamsRequestKeys): Observable<IRes<ICompany>> {
    return this.http.get<IRes<ICompany>>('companies', { params });
  }

  public getCompaniesPersonalList(companyId: number, params?: IParamsRequestKeys): Observable<IRes<IContact>> {
    return this.http.get<IRes<IContact>>(`personal/${companyId}`, { params });
  }

  public getCompany(id: number | string): Observable<ICompany> {
    return this.http.get<ICompany>(`company/${id}`);
  }

  public saveCompany(company: Partial<ICompany>): Observable<any> {
    return this.http.post('company', company);
  }

  public updateCompany(companyId: number, company: ICompany): Observable<ICompany> {
    return this.http.patch<ICompany>(`company/${companyId}`, company);
  }

  public deleteCompany(id: number): Observable<any> {
    return this.http.delete(`company/${id}`);
  }

  public deleteContactFromCompany(companyId: number, contactId: number): Observable<any> {
    return this.http.delete(`company/${companyId}/contact/${contactId}`);
  }
}
