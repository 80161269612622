import { Injectable } from '@angular/core';
import { NotificationsApiService } from '@api/notifications-api.service';
import { INotification } from '@common/interfaces/notification.interface';
import { IUser } from '@common/interfaces/user.interface';
import { selectUser } from '@core/store/user/user.reducers';
import { Store } from '@ngrx/store';
import { BehaviorSubject, filter, first, Observable, switchMap } from 'rxjs';

@Injectable()
export class NotificationsService {
  public notifications$!: Observable<INotification[] | null>;
  public userID!: string;
  private _updateSubject$: BehaviorSubject<null | []> = new BehaviorSubject<null | []>(null);

  constructor(
    private notificationsApiService: NotificationsApiService,
    private store: Store
  ) {
    this.notifications$ = this._updateSubject$.pipe(
      switchMap(() =>
        this.store.select(selectUser).pipe(
          filter((res): res is IUser => !!res),
          first()
        )
      ),

      switchMap((user: IUser) => {
        this.userID = user.id as string;
        return this.notificationsApiService.getNotifications(this.userID);
      })
    );
  }

  public delete(id: number): void {
    this.notificationsApiService.deleteNotification(id).subscribe(() => this.updateData());
  }

  public markAsRead(id: number): void {
    this.notificationsApiService.updateNotificationStatus(id).subscribe(() => this.updateData());
  }

  public deleteAll(): void {
    this.notificationsApiService.deleteAllNotifications(this.userID).subscribe(() => this.updateData());
  }

  public markAllAsRead(): void {
    this.notificationsApiService.updateAllNotificationsStatus(this.userID).subscribe(() => this.updateData());
  }

  public updateData(): void {
    this._updateSubject$.next([]);
  }
}
