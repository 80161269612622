<div class="notifications-wrapper">
  <div class="header">
    <h3>Notifications</h3>
  </div>

  <ng-container *ngIf="notificationsList?.length; else elseBlock">
    <div class="notifications-list">
      <div class="notification-item flex-row justify-space-between" *ngFor="let notification of notificationsList">
        <div class="flex-row">
          <div class="dot" [class.hidden]="notification.status"></div>

          <p [class.read]="notification.status" class="text">
            Your department assigned to a
            <a [routerLink]="['', 'proposal', notification.info.id]" (click)="markAsRead(notification.id)">{{
              notification.info.name
            }}</a>
            proposal.
          </p>
        </div>

        <delete-icon [ask]="false" (deleteItem)="remove(notification.id)">delete</delete-icon>
      </div>
    </div>

    <div class="buttons flex-row justify-space-between">
      <button (click)="removeAll()" class="notification-action-btn">Clear all</button>
      <button (click)="markAllAsRead()" class="notification-action-btn">Mark all as read</button>
    </div>
  </ng-container>

  <ng-template #elseBlock>
    <h4 class="message">No notifications yet</h4>
  </ng-template>
</div>
