import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationsApiService } from '@api/notifications-api.service';
import { IBaseModal, IDataAfterCloseModal } from '@common/interfaces/base.interfaces';
import { INotification } from '@common/interfaces/notification.interface';
import { AskModalComponent } from '@common/modules/modals/ask-modal/ask-modal.component';
import { NotificationsService } from '../../services/notifications.service';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
})
export class NotificationsListComponent {
  @Input({ required: true }) public notificationsList: INotification[] | null = null;
  @Input({ required: true }) public hasUnreadNotifications = false;

  @Output() public closeNotifications: EventEmitter<any> = new EventEmitter();

  public notifications$ = this.notificationsService.notifications$;

  constructor(
    private notificationsApiService: NotificationsApiService,
    private notificationsService: NotificationsService,
    private dialog: MatDialog
  ) {}

  public markAsRead(id: number): void {
    this.notificationsService.markAsRead(id);
    this.closeNotifications.emit();
  }

  public markAllAsRead(): void {
    this.notificationsService.markAllAsRead();
  }

  public remove(id: number): void {
    this.notificationsService.delete(id);
  }

  public removeAll(): void {
    this.hasUnreadNotifications
      ? this.dialog
          .open<AskModalComponent, IBaseModal, IDataAfterCloseModal>(AskModalComponent, {
            data: {
              title: 'Warning!',
              question: 'There are some unread notifications. Are you sure?',
            },
          })
          .afterClosed()
          .subscribe(res => {
            if (res?.isUpgrade) {
              this.notificationsService.deleteAll();
            }
          })
      : this.notificationsService.deleteAll();
  }
}
