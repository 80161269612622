import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationsApiService } from '@api/notifications-api.service';
import { INotification } from '@common/interfaces/notification.interface';
import { IUser } from '@common/interfaces/user.interface';
import * as AuthActions from '@core/store/auth/auth.actions';
import { selectUser } from '@core/store/user/user.reducers';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable, filter } from 'rxjs';
import { NotificationsService } from './services/notifications.service';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public userData$: Observable<IUser | null> = this.store.select(selectUser);
  public notificationsList: INotification[] = [];

  public isUserMenuOpen = false;
  public isUserLogged = false;
  public isNotificationsOpen = false;
  public hasUnreadNotifications = false;

  constructor(
    private store: Store,
    private notificationsService: NotificationsService,
    private notificationsApiService: NotificationsApiService
  ) {}

  ngOnInit(): void {
    this.notificationsService.notifications$
      .pipe(
        untilDestroyed(this),
        filter(res => !!res)
      )
      .subscribe(res => {
        this.notificationsList = res?.reverse() ?? [];

        this.hasUnreadNotifications = Boolean(res?.some(notification => !notification.status));
      });
  }

  public sentTestNotification(): void {
    this.notificationsApiService.postTestNotification().subscribe();
  }

  public toggleMenu(): void {
    this.isUserMenuOpen = !this.isUserMenuOpen;
  }

  public hideMenu(): void {
    this.isUserMenuOpen = false;
  }

  public logOut(): void {
    this.store.dispatch(AuthActions.LogOutAction());
  }

  public toggleNotifications(): void {
    this.isNotificationsOpen = !this.isNotificationsOpen;
  }

  public hideNotifications(): void {
    this.isNotificationsOpen = false;
  }

  ngOnDestroy() {
    return;
  }
}
