// view-container-ref.service.ts
import { Injectable, ViewContainerRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ViewContainerRefService {
  private viewContainerRef: ViewContainerRef | null = null;

  setViewContainerRef(viewContainerRef: ViewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }

  getViewContainerRef(): ViewContainerRef | null {
    return this.viewContainerRef;
  }
}
