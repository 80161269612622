import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IToken } from '@common/interfaces/auth.interface';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

interface AuthResponse {
  access_token: string;
  id_token: string;
  expires_in: number;
  scope: string;
  token_type: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthCustomService {
  constructor(private http: HttpClient) {}

  login({ email, password }: { email: string; password: string }): Observable<IToken> {
    const loginData = {
      grant_type: 'password',
      username: email,
      password: password,
      audience: environment.audience,
      client_id: environment.clientId,
    };
    return this.http.post<IToken>(`https://${environment.auth0Domain}/dbconnections/signup `, loginData);
  }

  refreshToken(): Observable<any> {
    return this.http.post('refresh-access-token', {}, { withCredentials: true });
  }
}
