import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoaderService, LoaderState } from '@core/services/loader.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription, debounce, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-line-loader',
  templateUrl: './line-loader.component.html',
  styleUrls: ['./line-loader.component.scss'],
  standalone: true,
  imports: [AsyncPipe, NgIf],
})
export class LineLoaderComponent implements OnInit, OnDestroy {
  public show = false;
  private subscription!: Subscription;

  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    this.subscription = this.loaderService.loaderState
      .pipe(
        map((state: LoaderState) => {
          if (state.show === true) {
            this.show = true;
          }
          return state;
        }),
        debounce(() => timer(500))
      )
      .subscribe((state: LoaderState) => {
        this.show = state.show;
      });
  }

  ngOnDestroy() {
    return;
  }
}
