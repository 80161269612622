import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseData } from '@common/interfaces/base.interfaces';
import { Observable } from 'rxjs';

export interface IStates extends IBaseData {
  full_name: string;
}

@Injectable({ providedIn: 'root' })
export class StatesService {
  constructor(private http: HttpClient) {}

  public getStatesList(): Observable<IStates[]> {
    return this.http.get<IStates[]>('states');
  }
}
