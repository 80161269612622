import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-notifications-icon',
  templateUrl: './notifications-icon.component.html',
  styleUrls: ['./notifications-icon.component.scss'],
})
export class NotificationsIconComponent {
  @Input() hasUnreadNotifications = false;
}
