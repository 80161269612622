import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { PrimaryBtnModule } from '@common/modules/UI/primary-btn/primary-btn.module';
import { AskModalComponent } from './ask-modal.component';

@NgModule({
  declarations: [AskModalComponent],
  imports: [CommonModule, MatIconModule, PrimaryBtnModule],
  exports: [AskModalComponent],
})
export class AskModalModule {}
