import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = request.url;

    if (!url.match(/(assets|\.json)/) && !url.match(environment.host) && !url.match('https')) {
      request = request.clone({
        url: `${environment.host}/${request.url}`,
      });
    }

    request = request.clone({
      withCredentials: true,
    });
    return next.handle(request);
  }
}
