import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserApiService } from '@api/user-api.service';
import { SocketService } from '@core/services/socket.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as AuthActions from '../auth/auth.actions';
import * as UserActions from './user.actions';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userApiService: UserApiService,
    private route: Router,
    private socketService: SocketService
  ) {}

  loadUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.LoadUser, AuthActions.LogInResponseAction),
      switchMap(() =>
        this.userApiService.getProfile().pipe(
          map(({ user, socket_token }) => {
            this.socketService.token = socket_token;
            this.socketService.connect();

            return UserActions.LoadUserSuccess({ user });
          }),
          catchError(error => of(UserActions.LoadUserError({ error })))
        )
      )
    );
  });

  // loadUserSuccess$ = createEffect(
  //   () => {
  //     return this.actions$.pipe(
  //       ofType(UserActions.LoadUserSuccess),
  //       switchMap(() => EMPTY)
  //     );
  //   },
  //   { dispatch: false }
  // );
}
