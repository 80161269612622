import { Injectable } from '@angular/core';
import { BuildingTypeService } from '@api/building-type.service';
import { CategoriesApiService } from '@api/categories.service';
import { CompaniesApiService } from '@api/companies-api.service';
import { ContactApiService } from '@api/contact-api.service';
import { DepartmentsApiService } from '@api/departments-api.service';
import { EnergyApiService } from '@api/energy-api.service';
import { GroupsApiService } from '@api/groups-api.service';
import { InspectionsApiService } from '@api/inspections-api.service';
import { LineItemsApiService } from '@api/line-items-api.service';
import { OccupancyApiService } from '@api/occupancy-api.service';
import { OccupationApiService } from '@api/occupation-api.service';
import { OfficeApiService } from '@api/office-api.service';
import { PriceConfigService } from '@api/price-config.service';
import { ProposalApiService } from '@api/proposal-api.service';
import { RolesApiService } from '@api/roles-api.service';
import { ServicesApiService } from '@api/services-api.service';
import { StandardsApiService } from '@api/standards-api.service';
import { StatesService } from '@api/states.service';
import { SustainabilityUtilsApiService } from '@api/sustainability-utils-api.service';
import { TeamsApiService } from '@api/teams-api.service';
import { UserApiService } from '@api/user-api.service';
import { CacheService } from '@core/services/cache.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalCacheService extends CacheService {
  constructor(
    private userApiService: UserApiService,
    private proposalApiService: ProposalApiService,
    private clientApiService: ContactApiService,
    private occupancyApiService: OccupancyApiService,
    private rolesApiService: RolesApiService,
    private energyCodeApiService: EnergyApiService,
    private sustainabilityUtilsApiService: SustainabilityUtilsApiService,
    private departmentsApiService: DepartmentsApiService,
    private groupsApiService: GroupsApiService,
    private teamsApiService: TeamsApiService,
    private officeApiService: OfficeApiService,
    private inspectionsApiService: InspectionsApiService,
    private servicesApiService: ServicesApiService,
    private standardsApiService: StandardsApiService,
    private buildingTypeService: BuildingTypeService,
    private statesService: StatesService,
    private lineItemsApiService: LineItemsApiService,
    private priceConfigService: PriceConfigService,
    private occupationApiService: OccupationApiService,
    private companiesApiService: CompaniesApiService,
    private categoriesService: CategoriesApiService
  ) {
    super();
  }

  public init(): void {
    this.add<UserApiService>(this.userApiService, 'getProfile');
    this.add<UserApiService>(this.userApiService, 'getUsers');
    this.add<ProposalApiService>(this.proposalApiService, 'getProjectConditions');
    this.add<ProposalApiService>(this.proposalApiService, 'getDesignPhases');
    this.add<ProposalApiService>(this.proposalApiService, 'getConstructionPhases');
    this.add<ProposalApiService>(this.proposalApiService, 'getOccupanciesList');
    this.add<ContactApiService>(this.clientApiService, 'getContactsList'); // check it in other place
    this.add<OccupancyApiService>(this.occupancyApiService, 'getOccupancyGroups');
    this.add<RolesApiService>(this.rolesApiService, 'getAccessLevelsList');
    this.add<EnergyApiService>(this.energyCodeApiService, 'getEnergyCodesList', { limit: 'all' });
    this.add<SustainabilityUtilsApiService>(this.sustainabilityUtilsApiService, 'getBuildGreenLevelsList');
    this.add<DepartmentsApiService>(this.departmentsApiService, 'getDepartmentsVsGroupsList');
    this.add<DepartmentsApiService>(this.departmentsApiService, 'getDepartmentsList');
    this.add<GroupsApiService>(this.groupsApiService, 'getUserGroups');
    this.add<TeamsApiService>(this.teamsApiService, 'getUserTeams');
    this.add<OfficeApiService>(this.officeApiService, 'getOfficesList');
    this.add<InspectionsApiService>(this.inspectionsApiService, 'getInspectionsList');
    this.add<ServicesApiService>(this.servicesApiService, 'getServicesList');
    this.add<DepartmentsApiService>(this.departmentsApiService, 'getDepartmentServices');
    this.add<StandardsApiService>(this.standardsApiService, 'getStandardList');
    this.add<StandardsApiService>(this.standardsApiService, 'getStandardTypesList');
    this.add<StandardsApiService>(this.standardsApiService, 'getStandardsList');
    this.add<BuildingTypeService>(this.buildingTypeService, 'getBuildingTypesList', { limit: 'all' });
    this.add<StatesService>(this.statesService, 'getStatesList');
    this.add<LineItemsApiService>(this.lineItemsApiService, 'getLineItemsList');
    this.add<PriceConfigService>(this.priceConfigService, 'getPriceConfigList');
    this.add<ServicesApiService>(this.servicesApiService, 'getServicesList');
    this.add<OccupationApiService>(this.occupationApiService, 'getOccupations', { limit: 'all' });
    this.add<CompaniesApiService>(this.companiesApiService, 'getCompaniesList');
    this.add<CategoriesApiService>(this.categoriesService, 'getCategoriesList', (data: any) => {
      data;
    });
    this.add<LineItemsApiService>(this.lineItemsApiService, 'getLineItemsGroups');
  }
}
