import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IBaseModal, IDataAfterCloseModal } from '@common/interfaces/base.interfaces';
import { AskModalComponent } from '@common/modules/modals/ask-modal/ask-modal.component';
import { filter } from 'rxjs';

@Component({
  selector: 'delete-icon',
  templateUrl: './delete-icon.component.html',
  styleUrls: ['./delete-icon.component.scss'],
})
export class DeleteIconComponent {
  @Input() itemName: string | null = null;
  @Input() ask = true;
  @Output() private readonly deleteItem: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private dialog: MatDialog) {}

  public delete(): void {
    this.ask
      ? this.dialog
          .open<AskModalComponent, IBaseModal, IDataAfterCloseModal>(AskModalComponent, {
            data: {
              title: 'Warning',
              question: `Are you sure you want to delete ${this.itemName ? '"' + this.itemName + '"' : 'this item'}?`,
            },
          })
          .afterClosed()
          .pipe(filter<IDataAfterCloseModal | undefined>(res => res?.isUpgrade === true))
          .subscribe(_ => this.deleteItem.emit(true))
      : this.deleteItem.emit(true);
  }
}
