import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IBaseModal, IDataAfterCloseModal } from '@common/interfaces/base.interfaces';

@Component({
  selector: 'app-ask-modal',
  templateUrl: './ask-modal.component.html',
  styleUrls: ['./ask-modal.component.scss'],
})
export class AskModalComponent implements OnInit {
  public data!: IBaseModal;

  constructor(
    private dialogRef: MatDialogRef<AskModalComponent, IDataAfterCloseModal>,
    @Inject(MAT_DIALOG_DATA) private _data: IBaseModal
  ) {}

  ngOnInit(): void {
    this.data = this._data;
  }

  public submit(): void {
    this.closeDialog(true);
  }

  public closeDialog(isUpgrade = false): void {
    this.dialogRef.close({ isUpgrade });
  }
}
