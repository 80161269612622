import { CommonModule, NgStyle } from '@angular/common';
import { AfterViewInit, Component, Input, Renderer2, ViewChildren } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-api-notification',
  template: `
    <div
      class="notification"
      #notificationWrap
      [ngStyle]="{
        'background-color': backgroundColor,
        top: 4.5 + 4.5 * notificationCount + 'em'
      }">
      <mat-icon>done</mat-icon>
      {{ message }}
    </div>
  `,
  styles: [
    `
      .notification {
        position: fixed;
        padding: 10px;
        border-radius: 5px;
        color: #fff;
        z-index: 1000;
        //top: 4.5em;
        right: 0;
        width: 15.75em;
        max-width: calc(100% - 3em);
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        display: flex;
        align-items: center;

        &.show {
          animation: flyIn 0.25s ease-out;
        }

        &.hide {
          animation: flyOut 0.25s ease-out;
        }

        mat-icon {
          margin-right: 10px;
          border: 2px solid #fff;
          border-radius: 50%;
          font-size: 20px;
          width: 26px;
          height: 26px;
          line-height: 22px;
          text-align: center;
        }
      }

      /* Animations */
      @keyframes flyIn {
        from {
          transform: translateX(calc(100% + 1.5em));
        }
        to {
          transform: translateX(0);
        }
      }

      @keyframes flyOut {
        from {
          transform: translateX(0);
        }
        to {
          transform: translateX(calc(100% + 1.5em));
        }
      }
    `,
  ],
  standalone: true,
  imports: [NgStyle, CommonModule, MatIconModule],
})
export class ApiNotificationComponent implements AfterViewInit {
  @Input() message = 'Saved successfully';
  @Input() backgroundColor = '#30B51E';
  @Input({ required: true }) duration = 1500;
  @Input({ required: true }) notificationCount = 0;
  @ViewChildren('notificationWrap') notification: any | undefined;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    const notificationElement = this.notification.first.nativeElement;
    this.renderer.addClass(notificationElement, 'show');
    setTimeout(() => {
      this.renderer.removeClass(notificationElement, 'show');
      this.renderer.addClass(notificationElement, 'hide');
      notificationElement.addEventListener('animationend', () => {
        this.renderer.removeChild(notificationElement.parentNode, notificationElement);
      });
    }, this.duration);
  }
}
