import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { INotification } from '@common/interfaces/notification.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationsApiService {
  constructor(private http: HttpClient) {}

  public getNotifications(userId: string): Observable<any> {
    return this.http.get<Observable<INotification[]>>(`notifications/${userId}`);
  }

  public updateNotificationStatus(id: number): Observable<any> {
    return this.http.put<any>(`notification/${id}`, null);
  }

  public updateAllNotificationsStatus(userId: string): Observable<any> {
    return this.http.put<any>(`notifications/${userId}`, null);
  }

  public deleteNotification(id: number): Observable<any> {
    return this.http.delete<any>(`notification/${id}`);
  }

  public deleteAllNotifications(userId: string): Observable<any> {
    return this.http.delete<any>(`notifications/${userId}`);
  }

  public postTestNotification(): Observable<any> {
    return this.http.post<any>('test-notification', null);
  } // only for testing
}
