import { Injectable } from '@angular/core';
import { NotificationsService } from '@common/modules/header/services/notifications.service';
import { environment } from '@environments/environment';
import * as io from 'socket.io-client';

@Injectable({ providedIn: 'root' })
export class SocketService {
  public host!: string;
  private socket: any;
  public token!: string;

  constructor(private notificationsService: NotificationsService) {
    // this.socket = io.connect(environment.host, { secure: true, auth: { token: 'sfg' } });
    // console.log('token', this.token);
  }

  public connect(): void {
    this.socket = io.connect(environment.host, { secure: true, auth: { token: this.token } });

    this.socket.on('connect', () => {
      console.log('a user connected');

      this.socket.on('disconnect', () => {
        console.log('user disconnected');
      });

      this.socket.on('proposal:create', (data: any) => {
        this.notificationsService.updateData();
      });
    });

    // this.socket.emit('join', this.token);
  }
}
