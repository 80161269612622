import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControlDirective,
  FormControlName,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { DropdownPosition, NgSelectComponent } from '@ng-select/ng-select';
import { GroupValueFn } from '@ng-select/ng-select/lib/ng-select.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { filter, Subject } from 'rxjs';
import * as Directives from './select.directives';

export type THintPosition = 'top' | 'left' | 'right';

export interface ISelectSearchData {
  term: string;
  items: any[];
}

@UntilDestroy()
@Component({
  selector: 'custom-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
  host: {
    class: 'custom-select',
    style: 'display: block;',
  },
})
export class SelectComponent implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor {
  private _disabled = false;
  private _selectedOption: any;

  // NgSelect ui-components-components
  @ViewChild(NgSelectComponent) public ngSelectComponent!: NgSelectComponent;
  @ViewChild('select', { static: false }) private selectContainer!: ElementRef;

  // Form controls optional directives
  @ContentChild(FormControlDirective)
  private _parentFormControl!: FormControlDirective;
  @ContentChild(FormControlName)
  private _parentFormControlName!: FormControlName;

  // Template directives
  @ContentChild(Directives.SelectHeaderDirective, { read: TemplateRef })
  public headerTemplate!: TemplateRef<any>;
  @ContentChild(Directives.SelectOptionDirective, { read: TemplateRef })
  public optionTemplate!: TemplateRef<any>;
  @ContentChild(Directives.SelectLabelDirective, { read: TemplateRef })
  public labelTemplate!: TemplateRef<any>;
  @ContentChild(Directives.SelectNotFountDirective, { read: TemplateRef })
  public notFountTemplate!: TemplateRef<any>;
  @ContentChild(Directives.SelectFooterDirective, { read: TemplateRef })
  public footerTemplate!: TemplateRef<any>;
  @ContentChild(Directives.SelectTypeHeadDirective, { read: TemplateRef })
  public typeHeadTemplate!: TemplateRef<any>;
  @ContentChild(Directives.SelectMultiLabelDirective, { read: TemplateRef })
  public multiLabelTemplate!: TemplateRef<any>;
  @ContentChild(Directives.SelectOptGroupDirective, { read: TemplateRef })
  public optGroupTemplate!: TemplateRef<any>;
  @ContentChild(Directives.SelectIconDirective, { read: TemplateRef })
  public iconTemplate!: TemplateRef<any>;

  // Check top hint and error directives
  @ContentChildren(Directives.SelectTopHintDirective, { descendants: true })
  public selectTopHintChildren!: QueryList<Directives.SelectTopHintDirective>;
  @ContentChildren(Directives.SelectErrorDirective, { descendants: true })
  public selectErrorChildren!: QueryList<Directives.SelectErrorDirective>;

  // Custom disabled class
  @HostBinding('class.custom-select-disabled') get disableState() {
    return this._disabled;
  }

  // Inputs
  @Input() public appendTo!: string;
  @Input() public searchable = true;
  @Input() public bindValue = 'id';
  @Input() public bindLabel = 'name';
  @Input() public items!: any[];
  @Input() public clearable = false;
  @Input() public readonly = false;
  @Input() public placeholder!: string;
  @Input() public multiple = false;
  @Input() public multipleWithoutCheckboxes = false;
  @Input() public hintPosition: THintPosition = 'top';
  @Input() public dropdownPosition: DropdownPosition = 'auto';
  @Input() public typeahead!: Subject<string>;
  @Input() public hideEmptyList = false;
  @Input() public compareWith: (a: any, b: any) => boolean = (a, b) => {
    if (!this.bindValue) return JSON.stringify(a) === JSON.stringify(b);
    if (typeof a !== 'object' && typeof b !== 'object') {
      return a === b;
    } else if (typeof a !== 'object') {
      return a === b[this.bindValue];
    } else if (typeof b !== 'object') {
      return a[this.bindValue] === b;
    }
    return a[this.bindValue] === b[this.bindValue];
  };
  @Input() public selectableGroup = false;
  @Input() public selectableGroupAsModel = false;
  @Input() public hideSelected = false;
  @Input() public groupBy!: string;
  @Input() public groupValue!: GroupValueFn;
  @Input() public closeOnSelect = true;
  @Input() public minTermLength = 0;
  @Input() public typeToSearchText = '';
  @Input() public trackByFn: any;
  @Input() public loading = false;
  @Input() public styleClass?: string;
  @Input() public required = false;
  @Input() public dropdownListPositionFixed = false;
  @Input() public searchFn: any = null;
  @Input() public notFoundText = 'No Items Found';
  @Input() public keyDownFn: (_: KeyboardEvent) => boolean = () => true;
  @Input() public keepSearchTerm = false;
  // Outputs
  @Output() readonly searchEmit = new EventEmitter<ISelectSearchData>();
  @Output() readonly changeNgSelectEmit = new EventEmitter<any>();
  @Output() readonly opened = new EventEmitter<any>();
  @Output() readonly keydownEnter = new EventEmitter<string>();
  @Output() readonly clear = new EventEmitter();

  public ngSelectControl: UntypedFormControl;
  public isItemsSimpleArray = false;
  public lastEnteredTerm = '';

  constructor() {
    this.ngSelectControl = new UntypedFormControl(null, this.required ? Validators.required : null);
  }

  ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['items'] && changes['items'].currentValue) {
      this.isItemsSimpleArray =
        Array.isArray(this.items) && this.items.every(value => typeof value === 'string' || typeof value === 'number');
      this.writeValue(this._selectedOption);
    }
  }

  public ngAfterViewInit(): void {
    if (this.keepSearchTerm && this.typeahead) {
      this.typeahead
        .pipe(
          filter(term => term !== null) // null means the field programmatically cleared
        )
        .subscribe(term => {
          this.lastEnteredTerm = term;
        });
    } else {
      // Not possible to observe last entered term
      this.keepSearchTerm = false;
    }
  }

  public openDropPanelHandler(): void {
    // !this.ngSelectComponent.isOpen && this.ngSelectComponent.open();
  }

  public changeHandler(value: any): void {
    if (value?.disabled) return;
    this.changeNgSelectEmit.next(value);

    if (!value) {
      this.writeValue(value);
      return;
    }

    this.writeValue(
      this.multiple || this.multipleWithoutCheckboxes || this.isItemsSimpleArray ? value : value[this.bindValue]
    );
  }

  public setDisabledState(disableState: boolean): void {
    this._disabled = disableState;
    disableState ? this.ngSelectControl.disable() : this.ngSelectControl.enable();
  }

  public focusEvent() {
    if (!this.dropdownListPositionFixed) {
      return;
    }
    this._getItemsListEl();
  }

  private _getItemsListEl() {
    // if (!count) { return; }

    setTimeout(() => {
      const panelItems = this.selectContainer.nativeElement.querySelector('.list-fixed .ng-dropdown-panel');
      if (!panelItems) {
        this._getItemsListEl();
      } else {
        const select = this.selectContainer.nativeElement.getElementsByTagName('ng-select')[0];
        panelItems.style.width = `${select.offsetWidth}px`;

        if (this.dropdownListPositionFixed) {
          panelItems.style.top = `${select.getBoundingClientRect().top + select.offsetHeight}px`;
        }

        return panelItems;
      }
    }, 2);
  }

  public writeValue(value: any): void {
    this._selectedOption = value;
    if (typeof value !== 'object' || Array.isArray(value)) {
      this.setNgSelectControl(value);
    }
    this.onChange(value);
    if (this.ngSelectComponent && this.keepSearchTerm) {
      this.ngSelectComponent.searchTerm = this.lastEnteredTerm;
    }
  }

  public registerOnChange(fn: (...args: any) => {}): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public onChange: any = (...args: any) => {};
  public onTouched: any = () => {};

  public addDropdownPanelClass(): void {
    if ((!this.multiple && !this.styleClass) || !this.dropdownListPositionFixed) {
      return;
    }
    setTimeout(() => {
      const panel = document.querySelector('.ng-dropdown-panel');
      if (this.multiple) {
        panel?.classList.add('custom-select-multiple');
      }
      if (this.styleClass) {
        panel?.classList.add(this.styleClass);
      }

      if (this.dropdownListPositionFixed) {
        panel?.classList.add('fixed-list');
      }
    }, 0);
  }

  public onOpen(event: any) {
    this.opened.emit(event);
    if (this.appendTo) {
      this.addDropdownPanelClass();
    }
  }

  public close(): void {
    this.ngSelectComponent.close();
  }

  public onKeydownEnter(event: KeyboardEvent | any): void {
    const value = (event.target as HTMLInputElement).value;
    this.keydownEnter.next(value);
  }

  onBlur(): void {
    if (this.keepSearchTerm) {
      this.ngSelectComponent.searchTerm = this.lastEnteredTerm;
    }
  }

  onClear(): void {
    this.lastEnteredTerm = '';
    this.clear.emit();
  }

  private setNgSelectControl(value: any): void {
    // if (!this.items) return;
    this.ngSelectControl.setValue(value);
  }

  private get parentControl(): FormControlName | FormControlDirective {
    return this._parentFormControl || this._parentFormControlName;
  }
}
