import { IBuildingResponse } from '@common/interfaces/building-response.interface';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as BuildingActions from './buildings.actions';

export interface BuildingsState {
  selectedBuildingId: number | null;
  buildingsList: IBuildingResponse[];
  buildingData: any;
  isLoading: boolean;
  error: string | null;
}

export const initialBuildingsState: BuildingsState = {
  buildingsList: [],
  selectedBuildingId: null,
  buildingData: null,
  isLoading: false,
  error: null,
};
export const _buildingsReducer = createReducer(
  initialBuildingsState,

  on(BuildingActions.ClearBuildingsDataStoreAction, (): BuildingsState => ({ ...initialBuildingsState })),

  on(
    BuildingActions.SetBuildingsListAction,
    (state: BuildingsState, { buildingsList, selectedBuildingId }): BuildingsState => ({
      ...state,
      buildingsList,
      selectedBuildingId,
      isLoading: true,
      error: null,
    })
  ),
  on(
    BuildingActions.SetSelectedBuildingIdAction,
    (state: BuildingsState, { selectedBuildingId }): BuildingsState => ({
      ...state,
      selectedBuildingId,
      isLoading: true,
      error: null,
    })
  )
);

export const selectBuildingsState = createFeatureSelector<BuildingsState>('buildingsData');

export function buildingsReducer(state: BuildingsState | undefined, action: Action) {
  return _buildingsReducer(state, action);
}

export const selectBuildingsListData = createSelector(selectBuildingsState, (state: BuildingsState) => {
  return state.buildingsList;
});

export const selectBuildingIdInBuildingsStore = createSelector(selectBuildingsState, (state: BuildingsState) => {
  return state.selectedBuildingId;
});
