import { _ProposalEffects } from '@core/store/_proposal/_proposal.effects';
import { AuthEffects } from '../auth/auth.effects';
import { UserEffects } from '../user/user.effects';

export const Effects = [
  AuthEffects,
  UserEffects,
  _ProposalEffects,
  // LineItemsEffects,
];
