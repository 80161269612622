import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRes } from '@common/interfaces/base-table.interface';
import { IBaseData, IParamsRequestKeys } from '@common/interfaces/base.interfaces';
import { INormativeType, INormativeWithVersion } from '@common/interfaces/normative.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StandardsApiService {
  constructor(private http: HttpClient) {}

  // Standards
  public getStandardList(): Observable<IBaseData[]> {
    return this.http.get<IBaseData[]>('standards');
  }

  //  Standard Type
  public getStandardTypesList(params?: IParamsRequestKeys): Observable<IRes<INormativeType>> {
    return this.http.get<IRes<INormativeType>>('code-standard-types', { params });
  }

  public getStandardTypesListWithVersions(params?: IParamsRequestKeys): Observable<INormativeWithVersion[]> {
    return this.http.get<INormativeWithVersion[]>('code-standard-types-versions', { params });
  }

  public getStandardType(id: number): Observable<INormativeType> {
    return this.http.get<INormativeType>(`code-standard-type/${id}`);
  }

  public addCodeStandardType(data: any): Observable<any> {
    return this.http.post('code-standard-type', data);
  }

  public updateCodeStandardType(data: any, id: number): Observable<any> {
    return this.http.put(`code-standard-type/${id}`, data);
  }

  public deleteCodeStandardType(id: number): Observable<any> {
    return this.http.delete(`code-standard-type/${id}`);
  }

  public getStandardsDetails(): Observable<any> {
    return this.http.get('code-standard-types-details');
  }

  //   Standard-Item

  public getStandardItemsList(params?: IParamsRequestKeys): Observable<any> {
    return this.http.get('code-standards');
  }

  public getStandardItem(id: number): Observable<any> {
    return this.http.get(`code-standard/${id}`);
  }

  public addStandardItem(data: any): Observable<any> {
    return this.http.post('code-standard', data);
  }

  public updateStandardItem(data: any, id: number): Observable<any> {
    return this.http.put(`code-standard/${id}`, data);
  }

  //   new Api

  public getStandardsList(params?: IParamsRequestKeys): Observable<any> {
    return this.http.get('code-standards', { params });
  }

  public getAllStandardsList(params?: IParamsRequestKeys): Observable<any> {
    return this.http.get('code-standards', { params });
  }
}
