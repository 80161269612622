import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRes } from '@common/interfaces/base-table.interface';
import { IBaseData, IParamsRequestKeys } from '@common/interfaces/base.interfaces';
import { IProvision } from '@common/interfaces/provision';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BuildingTypeService {
  constructor(private http: HttpClient) {}

  public getBuildingTypesList(params?: IParamsRequestKeys): Observable<IRes<IProvision>> {
    return this.http.get<IRes<IProvision>>('building-types', { params });
  }

  public getBuildingType(id: number): Observable<IBaseData> {
    return this.http.get<IBaseData>(`building-type/${id}`);
  }

  public saveBuildingType(provision: IProvision): Observable<IProvision> {
    return this.http.post<IProvision>('building-type', provision);
  }

  public updateBuildingType(id: number, provision: IProvision): Observable<IProvision> {
    return this.http.put<IProvision>(`building-type/${id}`, provision);
  }

  public deleteBuildingType(id: number): Observable<any> {
    return this.http.delete(`building-type/${id}`);
  }
}
