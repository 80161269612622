import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AskModalModule } from '@common/modules/modals/ask-modal/ask-modal.module';
import { DeleteIconComponent } from './delete-icon.component';

@NgModule({
  declarations: [DeleteIconComponent],
  imports: [CommonModule, MatIconModule, AskModalModule],
  exports: [DeleteIconComponent],
})
export class DeleteIconModule {}
