<div class="modal-form-wrapper">
  <div class="header-container">
    <div class="header-title">
      {{ data.title }}
    </div>
    <button class="close-modal" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="main-content">
    <div class="question" *ngIf="data.text">{{ data.text }}</div>
    <div class="question" *ngIf="data.question">{{ data.question }}</div>
    <div class="action-buttons flex-row justify-center">
      <primary-btn [className]="'blue'" class="action-cancel" (click)="closeDialog()">Cancel</primary-btn>
      <primary-btn [className]="'grey'" (click)="submit()" class="action-submit">Yes</primary-btn>
    </div>
  </div>
</div>
