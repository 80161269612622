import { ApplicationRef, ComponentRef, Injectable, Injector, ViewContainerRef } from '@angular/core';
import { ApiNotificationComponent } from '@common/modules/UI/api-notification/api-notification.component';

@Injectable({ providedIn: 'root' })
export class ApiNotificationService {
  private notificationCount = 0;

  constructor(
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  public showNotification(
    viewContainerRef: ViewContainerRef,
    message: string | null = null,
    duration = 2250,
    success = true
  ): void {
    const componentRef: ComponentRef<ApiNotificationComponent> =
      viewContainerRef.createComponent(ApiNotificationComponent);
    if (message) {
      componentRef.instance.message = message;
    }
    componentRef.instance.backgroundColor = success ? '#30B51E' : '#F44336';
    componentRef.instance.duration = duration - 350;
    componentRef.instance.notificationCount = this.notificationCount;
    this.notificationCount++;

    setTimeout(() => {
      componentRef.destroy();
      this.notificationCount--;
    }, duration);
  }
}
