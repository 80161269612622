import { INormativeWithVersion } from '@common/interfaces/normative.interface';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as CodesAndNormativesAction from './categories-and-codes.actions';

export interface ICategoriesAndCodesState {
  energyCodes: INormativeWithVersion[];
  sustainabilityCodes: INormativeWithVersion[];
  envelopCodes: INormativeWithVersion[];
  isLoading: boolean;
  error: any | null;
}

export const initialCAtegoriesAndCodesState: ICategoriesAndCodesState = {
  energyCodes: [],
  envelopCodes: [],
  sustainabilityCodes: [],
  isLoading: false,
  error: null,
};
export const _categoriesAndCodesReducer = createReducer(
  initialCAtegoriesAndCodesState,
  on(
    CodesAndNormativesAction.ClearCategoriesNormativesAction,
    (): ICategoriesAndCodesState => ({ ...initialCAtegoriesAndCodesState })
  ),
  on(
    CodesAndNormativesAction.SetEnergyCategoriesAction,
    (state: ICategoriesAndCodesState, { energyCodes }): ICategoriesAndCodesState => ({
      ...state,
      energyCodes,
      isLoading: true,
      error: null,
    })
  ),
  on(
    CodesAndNormativesAction.SetSustainabilityCategoriesAction,
    (state: ICategoriesAndCodesState, { sustainabilityCodes }): ICategoriesAndCodesState => ({
      ...state,
      sustainabilityCodes,
      isLoading: true,
      error: null,
    })
  ),
  on(
    CodesAndNormativesAction.SetEnvelopCategoriesAction,
    (state: ICategoriesAndCodesState, { envelopCodes }): ICategoriesAndCodesState => ({
      ...state,
      envelopCodes,
      isLoading: true,
      error: null,
    })
  )
);

export const selectCodesAndNormativesState = createFeatureSelector<ICategoriesAndCodesState>('CategoriesNormatives');

export const selectEnergyCodes = createSelector(selectCodesAndNormativesState, (state: ICategoriesAndCodesState) => {
  return state?.energyCodes;
});

export const selectSustainabilityCodes = createSelector(
  selectCodesAndNormativesState,
  (state: ICategoriesAndCodesState) => {
    return state?.sustainabilityCodes;
  }
);

export const selectEnvelopCodes = createSelector(selectCodesAndNormativesState, (state: ICategoriesAndCodesState) => {
  return state?.envelopCodes;
});

export function categoriesAndCodesReducer(state: ICategoriesAndCodesState | undefined, action: Action) {
  return _categoriesAndCodesReducer(state, action);
}
